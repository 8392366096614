/* Základní styly pro sekci pneumatik */
.tyre-section {
  display: flex;
  flex-direction: column;
  gap: 50px; 
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  margin-top: 0; /* Odstraní odsazení z horní strany */
  margin-bottom: 20px;
  width: 100%;
  height: 100%; /* Zajistí, že komponenta zabere celou dostupnou šířku */
  /* Přidání 'overflow: hidden;' zajišťuje, že všechny vnitřní prvky respektují zaoblené rohy kontejneru */
  overflow: hidden;
}

/* Styly pro kontejnery náprav */
.axle-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  gap: 0px; /* Odstup mezi pneumatikami a obrázkem nápravy */
}

/* Styly pro přední a zadní sekce */
.front-axles, .rear-axles {
  display: flex;
  flex-direction: column;
}

/* Styly pro obrazky náprav */
.axle-image {
  display: block;
  margin: 10px auto; /* Centruje obrazek nápravy v kontejneru */
  max-width: 150px; /* Zajišťuje, že obrazek nebude přesahovat kontejner */
  height: auto; /* Zachovává poměr stran obrazku */
}

/* Styly pro sekce pneumatik */
.tyres {
  display: flex;
  justify-content: center; /* Centruje pneumatiky v jejich sekci */
  gap: 5px; /* Vytváří mezery mezi pneumatikami */
}

.tyres-left, .tyres-right {
  flex: 1; /* Rozděluje prostor rovnoměrně mezi levou a pravou sekci pneumatik */
}

/* Specifické styly pro tlačítka pneumatik */
.tyre-button {
  background-color: #f0f0f0; /* Světlé pozadí pro tlačítka */
  border: none;
  padding: 5px 10px; /* Malý padding */
  border-radius: 4px; /* Zaoblené rohy */
  cursor: pointer; /* Změní kurzor na ukazatel při najetí */
  box-shadow: 0 1px 2px rgba(0,0,0,0.1); /* Přidává lehký stín */
}

.tyre-button:hover {
  background-color: #e2e2e2; /* Ztmaví tlačítko při najetí */
}

/* Styly pro kontejner nápravy */

.front-axles, .rear-axles {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rear-axles {
  margin-top: 50px; /* Přidává větší mezeru mezi přední a zadní sekcí */
}
